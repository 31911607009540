/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.main_spin {
  margin: 0 auto;
  border: 0px solid;
  display: flex;
  align-items: center;
  justify-content: center;
}

.div_main_spin_logo {
  position: absolute;
  align-items: center;
  border-radius: 50px;
  margin-top: 40px;
  width: 300px;
  /* height: 200px; */
  /* width: 200px; */
}

.div_spin_button {
  text-align: center;
  align-items: center;
  justify-content: center;
}

.playersCol {
  text-align: center;
} 

.textarea_names {
  text-align: left;
  width: 90%;
  border: 1px solid #cccccc;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  color: #797979;
  background: #e3d3ff;
  min-height: 150px !important;
  max-height: 70vh;
}

.input_number {
  text-align: right;
  border: 1px solid #cccccc6b;
  width: 50px !important;
  margin-left: 10px;
  margin-right: 10px;
}

.div_build_wheel {
  margin-left: 0;
  margin-right: 0;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.build_wheel_button {
  margin-left: auto;
  margin-right: auto;
}
